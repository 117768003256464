import Vue from 'vue'
import Store from './vuex/store'
import Router from 'vue-router'
import Utilities from './utilities'

const LoginSalabam = () => import(/* webpackChunkName: "login-chunk" */'@/components/views/login-salabam')
  .then(function(LoginSalabam) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: LoginSalabam View loaded')
    return LoginSalabam
  })

const Notifications = () => import('@/components/views/notifications')
  .then(function(Notifications) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Notifications View loaded')
    return Notifications
  })

const Home = () => import(/* webpackChunkName: "home-chunk" */'@/components/views/home')
  .then(function(Home) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Home View loaded')
    return Home
  })

const NotFound = () => import(/* webpackChunkName: "home-chunk" */'@/components/views/not-found')
  .then(function(Home) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Home View loaded')
    return Home
  })

const HomeInSalabam= () => import(/* webpackChunkName: "home-chunk" */'@/components/views/home-in-salabam')
  .then(function(HomeInSalabam) {
    window.console.log('async: HomeInSalabam View loaded')
    return HomeInSalabam
  })

const Post = () => import(/* webpackChunkName: "post-chunk" */'@/components/views/blog/post')
  .then(function(Post) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Post View loaded')
    return Post
  })

const Search = () => import(/* webpackChunkName: "search-chunk" */'@/components/views/search')
  .then(function(Search) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Search View loaded')
    return Search
  })

const ShopList = () => import(/* webpackChunkName: "search-chunk" */'@/components/views/shop-list')
  .then(function(ShopList) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ShopList View loaded')
    return ShopList
  })

const ShopVoucher = () => import(/* webpackChunkName: "search-chunk" */'@/components/views/shop-voucher')
  .then(function(ShopVoucher) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ShopVoucher View loaded')
    return ShopVoucher
  })

const Property = () => import(/* webpackChunkName: "property-chunk" */'@/components/views/search-property')
  .then(function(Property) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Property View loaded')
    return Property
  })

const Checkout = () => import(/* webpackChunkName: "purchase-chunk" */'@/components/views/checkout')
  .then(function(Checkout) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Checkout View loaded')
    return Checkout
  })

const Confirm = () => import(/* webpackChunkName: "purchase-chunk" */'./components/views/confirm')
  .then(function(Confirm) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm Boxset View loaded')
    return Confirm
  })

const ConfirmBoxset = () => import(/* webpackChunkName: "purchase-chunk" */'@/components/views/confirm-boxset')
  .then(function(Confirm) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm Boxset View loaded')
    return Confirm
  })

const BeforeCheckout = () => import(/* webpackChunkName: "purchase-chunk" */'@/components/views/before-checkout')
  .then(function(Checkout) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Checkout Info View loaded')
    return Checkout
  })

  const GoogleMapList = () => import(/* webpackChunkName: "map-chunk" */'@/components/views/search-google-map-list')
  .then(function(GoogleMapList) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: GoogleMapList View loaded')
    return GoogleMapList
  })

const Maintenance = () => import(/* webpackChunkName: "maintenance-chunk" */'@/components/views/maintenance')
  .then(function(Maintenance) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Maintenance View loaded')
    return Maintenance
  })  

const Faq = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/faq')
  .then(function(Faq) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Faq View loaded')
    return Faq
  })

const Informazioni = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/informazioni')
  .then(function(Informazioni) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Informazioni View loaded')
    return Informazioni
  })

const Terms = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/terms')
  .then(function(Terms) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Terms View loaded')
    return Terms
  })

const ChiSiamo = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/chi-siamo')
  .then(function(ChiSiamo) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ChiSiamo View loaded')
    return ChiSiamo
  })

const ServiziBusiness = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/servizi-business')
  .then(function(ServiziBusiness) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ServiziBusiness View loaded')
    return ServiziBusiness
  })

const Assistenza = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/assistenza')
  .then(function(Assistenza) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Assistenza View loaded')
    return Assistenza
  })

  const Icons = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/icons')
  .then(function(Icons) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Icons View loaded')
    return Icons
  })

  const GiftCards = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/gift-cards')
  .then(function(GiftCards) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: GiftCards View loaded')
    return GiftCards
  })

const RiscattaSalabam = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/riscatta-salabam')
  .then(function(RiscattaSalabam) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: RiscattaSalabam View loaded')
    return RiscattaSalabam
  })

const JpSso = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/jp-sso')
  .then(function(JpSso) {
    window.console.log('async: JpSso View loaded')
    return JpSso
  })

const JpCheckout = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/jp-checkout')
  .then(function(JpCheckout) {
    window.console.log('async: JpCheckout View loaded')
    return JpCheckout
  })

const Error = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/error')
  .then(function(Error) {
    window.console.log('async: Error View loaded')
    return Error
  })

const Info = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/info')
  .then(function(Info) {
    window.console.log('async: Info View loaded')
    return Info
  })

const TicketCompliments = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/ticket-compliments')
  .then(function(TicketCompliments) {
    window.console.log('async: TicketCompliments View loaded')
    return TicketCompliments
  })

const LandingEtcEdenred = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-ticket-compliments-edenred')
  .then(function(LandingEtcEdenred) {
    window.console.log('async: LandingEtcEdenred View loaded')
    return LandingEtcEdenred
  })

  const LandingAon = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-aon')
  .then(function(LandingAon) {
    window.console.log('async: LandingAon View loaded')
    return LandingAon
  })

const LandingScalapay = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-scalapay')
  .then(function(LandingScalapay) {
    window.console.log('async: LandingScalapay View loaded')
    return LandingScalapay
  })

const PinkPalace = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/pink-palace-landing')
  .then(function(PinkPalace) {
    window.console.log('async: PinkPalace View loaded')
    return PinkPalace
  })

const LandingUpDay = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-up-day')
  .then(function(LandingUpDay) {
    window.console.log('async: LandingUpDay View loaded')
    return LandingUpDay
  })

const LandingBackToItaly = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-backtoitaly')
  .then(function(LandingBackToItaly) {
    window.console.log('async: LandingBackToItaly View loaded')
    return LandingBackToItaly
  })

const Blog = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/blog/blog')
  .then(function(Blog) {
    window.console.log('async: Blog View loaded')
    return Blog
  })

const BlogHomeCategory = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/blog/blog-home-category')
  .then(function(BlogHomeCategory) {
    window.console.log('async: BlogHomeCategory View loaded')
    return BlogHomeCategory
  })

const ScalapayConfirm = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/scalapay-confirm')
  .then(function(ScalapayConfirm) {
    window.console.log('async: ScalapayConfirm View loaded')
    return ScalapayConfirm
  })

const ScalapayCancel = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/scalapay-cancel')
  .then(function(ScalapayCancel) {
    window.console.log('async: ScalapayCancel View loaded')
    return ScalapayCancel
  })

const DoubleCheck = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/doublecheck/doublecheck')
  .then(function(DoubleCheck) {
    window.console.log('async: DoubleCheck View loaded')
    return DoubleCheck
  })
const SatispayConfirm = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/satispay-confirm')
  .then(function(SatispayConfirm) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SatispayConfirm View loaded')
    return SatispayConfirm
  })

const PaymentsLink = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/payments-link')
  .then(function(PaymentsLink) {
    window.console.log('async: PaymentsLink View loaded')
    return PaymentsLink
  })


Vue.use(Router)

let router = new Router({
    mode: process.env.VUE_APP_ROUTER_MODE,
    scrollBehavior: function(to) {

      if(process.env.VUE_APP_ROUTER_MODE == 'history' && to.path.indexOf('#') > 0){
        // route path /page#ancor scrolls to #ancor (only with router history mode)
        return {selector: to.path.substr(to.path.indexOf('#'))}
      }else{
        return {selector: '#app'}
      }

    },
    routes: [
      {
        name: 'post',
        path: '/post/:postId/:slug',
        component: Post,
        props: (route) => {

          let postId = Number.parseInt(route.params.postId, 10)
          if (Number.isNaN(postId)) postId = 0

          return {
            postId: postId,
            slug:   '',
          }

        },
        publicRoute: true,
      },
      {
        name: 'post-wp',
        path: '/business/:slug',
        alias: ['/consumer/:slug'],
        component: Post,
        props: (route) => {

          let slug = (route.params.slug)? route.params.slug : ''

          return {
            postId: 0,
            slug:   slug,
          }

        },
        publicRoute: true,
      },
      {
        path: '/ticket-compliments',
        name: 'ticket-compliments',
        component: TicketCompliments,
        publicRoute: true, //custom attribute
    },
      {
        path: '/ho-un-salabam',
        name: 'login-salabam',
        component: LoginSalabam,
        publicRoute: true, //custom attribute
      },
      {
        path: '/ho-un-salabam/:emailUrl/:salabamUrl',
        name: 'login-salabam-fields',
        component: LoginSalabam,
        props: true,
        publicRoute: true, //custom attribute
      },
      {
          path: '/notifications',
          name: 'notifications',
          component: Notifications,
          publicRoute: false, //custom attribute
      },
      {
          path: '/maintenance',
          name: 'maintenance',
          component: Maintenance,
          publicRoute: false, //custom attribute
      },     
      {
          path: '/homepage#in-salabam',
          name: 'homepage-in-salabam',
          component: Home,
          publicRoute: true, //custom attribute
      },
      {
        path: '/',
        alias: ['/index.html'],
        name: 'homepage',
        component: Home,
        publicRoute: true, //custom attribute
      },
      {
        path: '/in-salabam/:inSalabamId/:inSalabamTitle',
        name: 'in-salabam',
        component: HomeInSalabam,
        publicRoute: true, //custom attribute
        props: true
      },      
      {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
        publicRoute: false, //custom attribute
      },
      {
        path: '/checkout-direct/:boxsetIdFromUrl',
        name: 'checkout',
        component: Checkout,
        publicRoute: false, //custom attribute
        props:true,
      },
      {
        path: '/shop/:productCategory/:productName/:boxsetIdFromUrl',
        name: 'checkout',
        component: Checkout,
        publicRoute: false, //custom attribute
        props:true,
      },
      {
        path: '/confirm',
        name: 'confirm',
        component: Confirm,
        props: true,
        publicRoute: true, //custom attribute
      },
      {
        //http://localhost:8080/#/prenotazioni/LB3CL2U/7927002216768
        path: '/prenotazioni/:confirmcode/:itinerary_id',
        name: 'reservations',
        component: Confirm,
        publicRoute: true, //custom attribute
        props: (route) => {
          return {
            confirmcode: route.params.confirmcode,
            itinerary_id: route.params.itinerary_id,
            prerenderStatusCode: '404',
          }
        },
      },
      {
        path: '/conferma-cofanetto',
        name: 'confirm-boxset',
        component: ConfirmBoxset,
        props: true,
        publicRoute: true, //custom attribute
      },
      {
        path: '/ordini/:parsedEmailId/:integratedOrderId',
        name: 'order-boxset',
        component: ConfirmBoxset,
        publicRoute: true, //custom attribute
        props: (route) => {
          return {
            parsedEmailId: route.params.parsedEmailId,
            integratedOrderId: route.params.integratedOrderId,
            prerenderStatusCode: '404',
          }
        },
      },
      {
        name: 'shop-focused',
        path: '/shop/:focus',
        component: ShopList,
        props: (route) => {

          let focus = (route.params.focus)? route.params.focus : ''

          return {
            urlFocus: focus,
          }

        },
        publicRoute: true, //custom attribute
      },
      {
        name: 'shop',
        path: '/shop',
        alias: ['/shop/:focus', '/cat/:focus'],
        component: ShopList,
        props: (route) => {

          let focus = (route.params.focus)? route.params.focus : ''

          return {
            urlFocus: focus,
          }

        },
        publicRoute: true, //custom attribute
      },
      {
        path: '/voucher-perfetto',
        name: 'search-voucher',
        component: ShopVoucher,
        props: true,
        publicRoute: true, //custom attribute
      },
      {
        path: '/ricerca',
        name: 'search',
        component: Search,
        publicRoute: false, //custom attribute
        children: [
          {
            name: 'property',
            path: '/ricerca/property-:propertyId-:propertyBand',
            component: Property,
            props: true,
            publicRoute: true, //custom attribute
          },
          {
            name: 'map-list',
            path: '/ricerca/map-list',
            component: GoogleMapList,
            props: true,
            publicRoute: true, //custom attribute
          },
          {
            name: 'map-detail',
            path: '/ricerca/map-property',
            component: GoogleMapList,
            props: true,
            publicRoute: true, //custom attribute
          },
          {
            name: 'before-checkout',
            path: '/ricerca/come-prenotare',
            component: BeforeCheckout,
            publicRoute: false, //custom attribute
          },      
        ]
      },
      {
        path: '/faq',
        name: 'faq',
        component: Faq,
        publicRoute: true, //custom attribute
      },
      {
        path: '/informazioni',
        name: 'informazioni',
        component: Informazioni,
        publicRoute: true, //custom attribute
      },
      {
        path: '/termini-e-condizioni',
        name: 'terms',
        component: Terms,
        publicRoute: true, //custom attribute
      },
      {
        path: '/chi-siamo',
        name: 'chi-siamo',
        component: ChiSiamo,
        publicRoute: true, //custom attribute
        children:[
          {
            path: '/chi-siamo#per-i-viaggiatori',
            name: 'chi-siamo-per-i-viaggiatori',
            component: ChiSiamo,
            publicRoute: true, //custom attribute
          },
          {
            path: '/chi-siamo#per-le-aziende',
            name: 'chi-siamo-per-le-aziende',
            component: ChiSiamo,
            publicRoute: true, //custom attribute
          },
          {
            path: '/chi-siamo#per-gli-hotel-manager',
            name: 'chi-siamo-per-gli-hotel-manager',
            component: ChiSiamo,
            publicRoute: true, //custom attribute
          },
          {
            path: '/chi-siamo#per-le-ota',
            name: 'chi-siamo-per-le-ota',
            component: ChiSiamo,
            publicRoute: true, //custom attribute
          },
        ]
      }, // chi-siamo
      {
        path: '/servizi-business',
        name: 'servizi-business',
        component: ServiziBusiness,
        publicRoute: true, //custom attribute
        children:[
          {
            path: '/servizi-business#soluzioni-per-il-marketing',
            name: 'servizi-business-soluzioni-per-il-marketing',
            component: ServiziBusiness,
            publicRoute: true, //custom attribute
          },
          {
            path: '/servizi-business#per-il-welfare-aziendale',
            name: 'servizi-business-per-il-welfare-aziendale',
            component: ServiziBusiness,
            publicRoute: true, //custom attribute
          },
          {
            path: '/servizi-business#soluzioni-per-regali-e-pubbliche-relazioni',
            name: 'servizi-business-soluzioni-per-regali-e-pubbliche-relazioni',
            component: ServiziBusiness,
            publicRoute: true, //custom attribute
          },
          {
            path: '/servizi-business#blufreccia-sport-event-travel',
            name: 'servizi-business-blufreccia-sport-event-travel',
            component: ServiziBusiness,
            publicRoute: true, //custom attribute
          },
          {
            path: '/servizi-business#vivamod-modern-travel',
            name: 'servizi-business-vivamod-modern-travel',
            component: ServiziBusiness,
            publicRoute: true, //custom attribute
          },
        ]
      }, // servizi-business
      {
        path: '/assistenza',
        name: 'assistenza',
        component: Assistenza,
        publicRoute: true, //custom attribute
        children:[
          {
            path: '/assistenza#viaggi-sicuro',
            name: 'assistenza-viaggi-sicuro',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#covid19',
            name: 'assistenza-covid19',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#tecnologia',
            name: 'assistenza-tecnologia',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#acquista-con-il-welfare-aziendale',
            name: 'assistenza-acquista-con-il-welfare-aziendale',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#acquista-un-voucher-salabam',
            name: 'assistenza-acquista-un-voucher-salabam',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#regala-un-salabam',
            name: 'assistenza-regala-un-salabam',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#promozione-in-corso',
            name: 'assistenza-promozione-in-corso',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#prenota-un-viaggio',
            name: 'assistenza-prenota-un-viaggio',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#richiedi-un-preventivo',
            name: 'assistenza-richiedi-un-preventivo',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#segnala-un-problema',
            name: 'assistenza-segnala-un-problema',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
          {
            path: '/assistenza#contatti-contattaci',
            name: 'assistenza-contatti-contattaci',
            component: Assistenza,
            publicRoute: true, //custom attribute
          },
        ]
      }, // assistenza
      {
        path: '/icons',
        name: 'icons',
        component: Icons,
        publicRoute: true, //custom attribute
        development: true,
      },
      {
        path: '/gift-card-regalo',
        name: 'gift-cards',
        component: GiftCards,
        publicRoute: true, //custom attribute
      },
      {
        path: '/riscatta-salabam',
        name: 'riscatta-salabam',
        component: RiscattaSalabam,
        publicRoute: true, //custom attribute
      },
      {
        path: '/jp-sso',
        name: 'jp-sso',
        component: JpSso,
        publicRoute: true, //custom attribute
      },
      {
        path: '/jp-checkout',
        name: 'jp-checkout',
        component: JpCheckout,
        publicRoute: true, //custom attribute
      },
      {
        path: '/copy-scadenza-playground',
        name: 'copy-scadenza-playground',
        component: () => import('@/components/views/dev-pages/copy-scadenza-playground').then(function(CopyScadenzaPlayground){ window.console.log('async: CopyScadenzaPlayground loaded'); return CopyScadenzaPlayground;}),
        publicRoute: false, //custom attribute
        development: true,
      },
      {
        path: '/ticket-compliments-edenred',
        name: 'landing-etc-edenred',
        component: LandingEtcEdenred,
        publicRoute: true, //custom attribute
      },
      {
        path: '/aon',
        name: 'aon',
        component: LandingAon,
        publicRoute: true, //custom attribute
      },
      {
        path: '/scalapay',
        name: 'scalapay',
        component: LandingScalapay,
        publicRoute: true, //custom attribute
      },
      {
        path: '/pink-palace',
        name: 'pink-palace',
        component: PinkPalace,
        publicRoute: true, //custom attribute
      },
      {
        path: '/promo-day',
        name: 'up-day',
        component: LandingUpDay,
        publicRoute: true, //custom attribute
      },
      {
        path: '/backtoitaly',
        name: 'backtoitaly',
        component: LandingBackToItaly,
        publicRoute: true, //custom attribute
      },
      {
        path: '/blog',
        name: 'blog',
        component: Blog,
        publicRoute: true, //custom attribute
      },
      {
        path: '/consumer',
        name: 'blog-consumer',
        component: BlogHomeCategory,
        publicRoute: true, //custom attribute
        props: () => {
          return {
            categorySlug: 'consumer',
            metaTitle: 'La scelta dello staff',
            metaDescription: 'Lasciati ispirare! Qui troverai idee, consigli, suggestioni per vivere esperienze di viaggio straordinarie con Salabam',
          }
        }
      },
      {
        path: '/business',
        name: 'blog-business',
        component: BlogHomeCategory,
        publicRoute: true, //custom attribute
        props: () => {
            return {
              categorySlug: 'business',
              metaTitle: 'IN|Biz approfondimenti business',
              metaDescription: 'Viaggi, welfare aziendale, loyalty e marketing. Approfondimenti su travel e aziende',
            }
        }
      },
      {
        path: '/scalapay-confirm',
        name: 'scalapay-confirm',
        component: ScalapayConfirm,
        publicRoute: true, //custom attribute
      },
      {
        path: '/scalapay-cancel',
        name: 'scalapay-cancel',
        component: ScalapayCancel,
        publicRoute: true, //custom attribute
      },
      {
        path: '/satispay-confirm/:integratedOrderId/:cancellaraToken',
        name: 'satispay-confirm',
        component: SatispayConfirm,
        publicRoute: true, //custom attribute
        props: route => ({
          integratedOrderId: route.params.integratedOrderId,
          cancellaraToken: route.params.cancellaraToken,
        }),
      },
      {
        path: '/error',
        name: 'error',
        component: Error,
        publicRoute: true, //custom attribute
      },
      {
        path: '/info',
        name: 'info',
        component: Info,
        publicRoute: true, //custom attribute
      },
      {
        name: 'doublecheck',
        path: '/doublecheck/:voucher_id/:itinerary_id',
        component: DoubleCheck,
        props: true,
      },
      {
        path: '/paga/:pay_code/:payment_link_id',
        name: 'payments-link',
        component: PaymentsLink,
        publicRoute: true,
      },
      // gestisce i "404", !!! DEVE STARE PER ULTIMA !!!
      /*{
        path: '*',
        name: 'notFound',
        redirect: '/',
        publicRoute: true, //custom attribute
      },*/
      {
        path: '*',
        name: 'not-found',
        component: NotFound,
        props: {
          prerenderStatusCode: '404',
        }
      },
    ]
})


/*if (process.env.NODE_ENV === 'development') {
  
  window.console.log('router mode development')
  
  router.addRoutes([
    {
      path: '/copy-scadenza-playground',
      name: 'copy-scadenza-playground',
      component: () => import('@/components/views/dev-pages/copy-scadenza-playground').then(function(CopyScadenzaPlayground){ window.console.log('async: CopyScadenzaPlayground loaded'); return CopyScadenzaPlayground;}),
      publicRoute: true, //custom attribute
    },
  ]);
  
}*/



/* #VADOBAY: definire questi comportamenti di navigazione su salabam*/
router.beforeEach((to, from, next) => {
    
    // se devo andare in manutenzione non controllare nient'altro: vai in manutenzinoe
    if (to.name == 'maintenance')
    {
      next()
      return
    }

    let route = router.options.routes.filter(function (r) {
      if(to.matched.length > 0)
      {
        return r.name == to.matched[0].name
      }
    })[0]
    
    if(Store.state.unlockUpselling){
      // se è stato attivato upselling ma ho "abbandonato" le pagine della ricerca lo disattivo

      let upsellableRoutes = ['search', 'property', 'map-list', 'map-detail', 'before-checkout', 'checkout']

      if(!upsellableRoutes.includes(to.name)){

        if(process.env.VUE_APP_MODE != 'production') window.console.log('ROTTA NON UPSELLING, upselling disattivato')

        Utilities.methods.mixinSendMutation('setUnlockUpselling', false)

        // NOTA in realtà basta disattivare unlockUpselling e tutto fila
        // in caso di problemi, settare anche activeBand e nights con i valori del salabam (che non so dove recuperare)
        //Utilities.methods.mixinSendMutation('setActiveBand', bandaDelSalabamDelLogin_daTrovareDaQualcheParte)
        //Utilities.methods.mixinSendMutation('setNights', nottiDelSalabamDelLogin_daTrovareDaQualcheParte)

      } // !upsellableRoute

    } // if this.$store.state.unlockUpselling

    // pagine utility solo in dev
    // TODO impedisce il rendering se non dev, ma l'app si pianta
    if(process.env.NODE_ENV != 'development' && (route.development) ){
      Utilities.methods.mixinGoTo('homepage',{})
      return
    }
    
    // se c'è il token nell'url, lo salvo nello state
    if(to.query.token)
    {
      Utilities.methods.mixinSendMutation('setToken',to.query.token)
      //next()
      //return
    }

    // se la rotta è pubblica, proseguo la navigazione
    //if(route.publicRoute == true)
    //{
        next()
        //return
    //}
    /*
    // se la rotta NON è pubblica, controllo che ci sia un token
    else
    { 
        
      // se c'è il token, proseguo la navigazione
      if(Utilities.methods.mixinIsAuthenticated())
      {
          next()
          return
      }
      
      // se NON c'è il token, redirect da qualche parte
      Utilities.methods.mixinGoTo('how-it-works',{})
    }
    */
})


//router.afterEach((to, from) => {
router.afterEach((to) => {

  // per scrollare alle sezioni di id #id
  // bisogna gestirlo in parte manualmente
  // se hai già il sito caricato, i fragment funzionano
  // ma se arrivi da fuori con link con fragment, va gestito così
  if(to.hash && to.hash != ''){

    if(process.env.VUE_APP_MODE != 'production') window.console.log('fragment found')

    // TODO fare interval 500, check element id != undefined, trigger

    setTimeout(function(){

      if(process.env.VUE_APP_MODE != 'production') window.console.log('scrolling to ' + to.hash)
      document.getElementById(to.hash.substr(1)).scrollIntoView();

    }, 1500); // setTimeout

  }

})


export default router